import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#D42027" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/Sushi-Karis-269091593119142/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi Karis Korean Restaurant Facebook"
              />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/sushi-karis-coquitlam-coquitlam/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sushi Karis Korean Restaurant Zomato"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/sushi-karis-coquitlam">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Sushi Karis Korean Restaurant Yelp"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushikaris/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sushi Karis Korean Restaurant Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#D42027" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/Sushi-Karis-269091593119142/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sushi Karis Korean Restaurant Facebook"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.zomato.com/vancouver/sushi-karis-coquitlam-coquitlam/menu">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sushi Karis Korean Restaurant Zomato"
              />
            </CFLink>
            <CFLink href="https://www.yelp.ca/biz/sushi-karis-coquitlam">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Sushi Karis Korean Restaurant Yelp"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/sushikaris/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sushi Karis Korean Restaurant Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
