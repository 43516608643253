import { db } from 'cf-core/src/config/firebase'
import { moment } from 'cf-utils'
import { get, isEqual, isEmpty } from 'lodash'
import * as utils from 'cf-utils'
import * as api from '../api'

const DEFAULT_STATE = {
  // Restaurant data
  commissionFee: 0,
  Locations: {},
  name: null,
  landingUrl: '',
  images: {
    logo: '',
    logoTitle: '',
    mainBg: '',
    mainBgMobile: '',
    menuHeader: '',
    rewardBg: '',
    rewardContainer: '',
    rewardDefault: '',
    rewardRedeemButton: '',
  },
  styles: {
    fontColor: 'black',
    rewardsFontColor: 'black',
    theme: 'dark',
  },

  // Restaurant Location data
  activeCategoryOrder: [],
  activeProducts: {},
  address: null,
  Categories: {},
  categoryOrder: [],
  deliveryFee: 0,
  deliveryHours: {},
  deliveryOpen: null,
  deliveryTime: 0,
  deliveryZone: [],
  deposit: 0,
  email: null,
  firstOrderDiscount: 0,
  googleMapsUrl: '',
  pickupDiscount: 0,
  hours: {},
  locationName: null,
  menus: {},
  minOrder: 0,
  minOrderDiscount: 0,
  Modifiers: {},
  orderOpen: null,
  phoneNumber: null,
  printers: [],
  Products: {},
  requestTypes: [
    { name: 'Need Refill', icon: 'bell' },
    { name: 'Ready to Pay', icon: 'file-invoice-dollar' },
    { name: 'Call Server', icon: 'user-tie' },
  ],
  restaurantLocationLoading: true,
  rewards: {},
  Tables: {},
  waitTime: 0,
  welcomeMessage: {
    title: '',
    body: '',
  },

  // Flags
  deliveryEnabled: false,
  dineInEnabled: false,
  marketingEnabled: false,
  mobileAppEnabled: false,
  printingEnabled: false,
  rewardsEnabled: false,
  paymentMethodType: 'online',
  dineInPaymentMethodType: 'online',
  guestOrderingEnabled: false,

  // Redux only data
  loading: true,
  confirmingRequest: false,
  restaurantId: null,
  locationId: null,
  tableId: null,
}

export default (_restaurantId, _defaultLocationId, _tableId) => ({
  state: {
    ...DEFAULT_STATE,
    restaurantId: _restaurantId,
    locationId: _defaultLocationId,
    tableId: _tableId,
  },
  reducers: {
    resetRestaurant: ({ restaurantId, locationId, tableId }) => ({
      ...DEFAULT_STATE,
      restaurantId,
      locationId,
      tableId,
    }),
    resetRestaurantLocation: ({ restaurantId, Locations, loading, commissionFee, name }) => ({
      ...DEFAULT_STATE,
      restaurantId,
      Locations,
      loading,
      commissionFee,
      name,
    }),
    setRestaurantId: (state, restaurantId) => {
      return { ...state, restaurantId }
    },
    setLocationId: (state, locationId) => {
      return { ...state, locationId }
    },
    setTableId: (state, tableId) => {
      return {
        ...state,
        tableId,
      }
    },
    setRestaurant: (state, restaurant) => ({
      ...state,
      ...restaurant,
    }),
    setCategories: (state, Categories) => ({
      ...state,
      Categories,
    }),
    setProducts: (state, Products) => ({
      ...state,
      Products,
    }),
    setModifiers: (state, Modifiers) => ({
      ...state,
      Modifiers,
    }),
    setTables: (state, Tables) => ({
      ...state,
      Tables,
    }),
    setLocations: (state, Locations) => ({
      ...state,
      Locations,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setConfirmingRequest: (state, confirmingRequest) => ({
      ...state,
      confirmingRequest,
    }),
    setRestaurantLocationLoading: (state, restaurantLocationLoading) => ({
      ...state,
      restaurantLocationLoading,
    }),
    setActiveCategoryOrder: (state, activeCategoryOrder) => ({
      ...state,
      activeCategoryOrder,
    }),
    setActiveProducts: (state, activeProducts) => ({
      ...state,
      activeProducts,
    }),
  },
  actions: ({ dispatch, getState }) => ({
    getRestaurantId() {
      return getState().restaurant.restaurantId
    },
    getSelectedLocationId() {
      return getState().restaurant.locationId
    },
    getTableId() {
      return getState().restaurant.tableId
    },
    getSelectedLocation() {
      const locations = dispatch.restaurant.getLocations()
      const selectedLocationId = dispatch.restaurant.getSelectedLocationId()
      if (locations && selectedLocationId && locations[selectedLocationId]) {
        return locations[selectedLocationId]
      }
    },
    getImages() {
      return getState().restaurant.images
    },
    getStyles() {
      return getState().restaurant.styles
    },
    getWelcomeMessage() {
      return getState().restaurant.welcomeMessage
    },
    getLandingUrl() {
      return getState().restaurant.landingUrl
    },
    getName() {
      return getState().restaurant.name
    },
    getLocationName() {
      return getState().restaurant.locationName
    },
    getAddress() {
      return getState().restaurant.address
    },
    getGoogleMapsUrl() {
      if (getState().restaurant.googleMapsUrl) {
        return getState().restaurant.googleMapsUrl
      } else {
        return `https://maps.google.com/?q=${dispatch.restaurant.getAddress()}`
      }
    },
    getPhoneNumber() {
      return getState().restaurant.phoneNumber
    },
    getRequestTypes() {
      return getState().restaurant.requestTypes
    },
    getFormattedPhoneNumber() {
      return utils.formatPhoneNumber(dispatch.restaurant.getPhoneNumber())
    },
    getEmail() {
      return getState().restaurant.email
    },
    getHours() {
      return getState().restaurant.hours
    },
    getDeliveryHours() {
      return getState().restaurant.deliveryHours
    },
    getDeliveryHoursTime() {
      const hours = dispatch.restaurant.getDeliveryHours()
      const day = moment().day()
      return isEmpty(hours)
        ? 'Not Available'
        : hours[day] && hours[day].open && hours[day].close
        ? `${moment(hours[day].open).format('LT')} - ${moment(hours[day].close).format('LT')}`
        : 'Not Available'
    },
    getMenus() {
      return getState().restaurant.menus
    },
    getMenu(menuId) {
      return dispatch.restaurant.getMenus()[menuId] || {}
    },
    getMenuHours(menuId) {
      const { hours } = dispatch.restaurant.getMenu(menuId)
      const day = moment().day()
      return isEmpty(hours)
        ? 'Not Available'
        : hours[day] && hours[day].open && hours[day].close
        ? `${moment(hours[day].open).format('LT')} - ${moment(hours[day].close).format('LT')}`
        : 'Not Available'
    },
    getIsMenuActive(menuId) {
      return dispatch.restaurant.getMenu(menuId).active
    },
    getActiveMenuCount() {
      return Object.values(dispatch.restaurant.getMenus()).reduce((acc, menu) => {
        if (menu.active) {
          return (acc = acc + 1)
        }
        return acc
      }, 0)
    },
    getDeliveryEnabled() {
      return !!getState().restaurant.deliveryEnabled
    },
    getDineInEnabled() {
      return !!getState().restaurant.dineInEnabled
    },
    getIsDineIn() {
      return !!getState().restaurant.tableId && dispatch.restaurant.getDineInEnabled()
    },
    getMarketingEnabled() {
      return !!getState().restaurant.marketingEnabled
    },
    getMobileAppEnabled() {
      return !!getState().restaurant.mobileAppEnabled
    },
    getPrintingEnabled() {
      return !!getState().restaurant.printingEnabled
    },
    getPrinters() {
      return getState().restaurant.printers
    },
    getRewardsEnabled() {
      return !!getState().restaurant.rewardsEnabled
    },
    getGuestOrderingEnabled() {
      return !!getState().restaurant.guestOrderingEnabled
    },
    getPaymentMethodType() {
      if (dispatch.restaurant.getIsDineIn()) {
        return getState().restaurant.dineInPaymentMethodType
      }
      return getState().restaurant.paymentMethodType
    },
    getDeliveryFee() {
      return getState().restaurant.deliveryFee
    },
    getDeliveryZone() {
      return getState().restaurant.deliveryZone
    },
    getOrderOpen() {
      return !!getState().restaurant.orderOpen
    },
    getDeliveryOpen() {
      return !!getState().restaurant.deliveryOpen
    },
    getMinOrder() {
      return getState().restaurant.minOrder
    },
    createRequest(description) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const tableId = dispatch.restaurant.getTableId()
      const tableNumber = dispatch.restaurant.getTableNumber()
      const request = {
        createdAt: utils.moment().valueOf(),
        confirmed: false,
        tableId,
        tableNumber,
        description,
      }
      return api.restaurant.createRequest(restaurantId, locationId, request)
    },
    getConfirmingRequest() {
      return !!getState().restaurant.confirmingRequest
    },
    getIsStoreOpen() {
      // Store is open iff (not loading, orderOpen, now is during store hours)

      const orderOpen = getState().restaurant.orderOpen
      // return null if we don't know if order is open (ie. still loading)
      if (orderOpen === null) {
        return null
      }
      if (orderOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: moment(),
        time: moment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      if (todayOpen) {
        if (dispatch.restaurant.getIsStoreOnBreak()) {
          return false
        } else {
          return true
        }
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: moment().subtract(1, 'days'),
        time: moment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      return yesterdayOpen
    },
    getIsDeliveryHoursOpen() {
      // Store is open iff (not loading, orderOpen, now is during store hours)

      const deliveryOpen = getState().restaurant.deliveryOpen
      // return null if we don't know if order is open (ie. still loading)
      if (deliveryOpen === null) {
        return null
      }
      if (deliveryOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getDeliveryHours(),
        targetDate: moment(),
        time: moment(),
        waitTime: 0,
      })
      if (todayOpen) {
        if (dispatch.restaurant.getIsStoreOnBreak()) {
          return false
        } else {
          return true
        }
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getDeliveryHours(),
        targetDate: moment().subtract(1, 'days'),
        time: moment(),
        waitTime: 0,
      })
      return yesterdayOpen
    },
    getIsStoreOnBreak() {
      const time = moment()
      const hours = dispatch.restaurant.getHours()
      const weekday = time.weekday()
      const breakStart = get(hours[weekday], 'breakStart')
      const breakEnd = get(hours[weekday], 'breakEnd')
      if (breakStart) {
        const breakStartMoment = moment(breakStart).subtract(dispatch.restaurant.getWaitTime(), 'm')
        const breakEndMoment = moment(breakEnd)
        const breakStartTime = moment(time)
          .hours(breakStartMoment.hours())
          .minutes(breakStartMoment.minutes())
          .seconds(0)
        const breakEndTime = moment(time)
          .hours(breakEndMoment.hours())
          .minutes(breakEndMoment.minutes())
          .seconds(0)

        return time.isBetween(breakStartTime, breakEndTime)
      }
      return false
    },
    getRewards() {
      return getState().restaurant.rewards
    },
    getRewardsCount() {
      return Object.keys(dispatch.restaurant.getRewards()).length
    },
    getFirstOrderDiscount() {
      return getState().restaurant.firstOrderDiscount || 0
    },
    getPickupDiscount() {
      return getState().restaurant.pickupDiscount || 0
    },
    getMinOrderDiscount() {
      return getState().restaurant.minOrderDiscount || 0
    },
    getCommissionFee() {
      return getState().restaurant.commissionFee || 0
    },
    getDeliveryTime() {
      return getState().restaurant.deliveryTime || 0
    },
    getWaitTime() {
      return getState().restaurant.waitTime || 0
    },
    getCategoryOrder(menuId) {
      if (menuId) {
        return dispatch.restaurant.getMenu(menuId).categoryOrder || []
      }
      return getState().restaurant.categoryOrder
    },
    getCategoriesInOrder(admin = false) {
      const categories = dispatch.restaurant.getCategories()
      const activeCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()
      return activeCategoryOrder.reduce((prev, categoryId) => {
        const category = categories[categoryId]
        if (category) {
          const { orderType = 'all' } = category
          if (
            admin ||
            orderType === 'all' ||
            (orderType === 'dine-in' && dispatch.restaurant.getIsDineIn()) ||
            (orderType === 'dine-out' && !dispatch.restaurant.getIsDineIn())
          ) {
            prev.push(category)
          }
        }
        return prev
      }, [])
    },
    updateActiveCategoryOrder() {
      const menus = dispatch.restaurant.getMenus()
      const categoryOrder = Object.values(menus)
        .reverse()
        .reduce((prev, menu) => {
          const { active, hours } = menu
          if (active) {
            const isOpen = utils.getIsOpenForGivenTime({
              hours,
              targetDate: moment(),
              time: moment(),
              waitTime: 0,
            })

            if (isOpen) {
              const categoryOrder = get(menu, 'categoryOrder')
              prev = prev.concat(categoryOrder)
            } else {
              const isYesterdayOpen = utils.getIsOpenForGivenTime({
                hours,
                targetDate: moment().subtract(1, 'days'),
                time: moment(),
                waitTime: 0,
              })
              if (isYesterdayOpen) {
                const categoryOrder = get(menu, 'categoryOrder')
                prev = prev.concat(categoryOrder)
              }
            }
          }
          return prev
        }, [])
      const prevActiveCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()
      if (categoryOrder.length === 0 && menus.default) {
        if (!isEqual(prevActiveCategoryOrder, menus.default.categoryOrder)) {
          dispatch.restaurant.setActiveCategoryOrder(menus.default.categoryOrder)
          dispatch.restaurant.updateActiveProducts()
        }
      } else if (!isEqual(prevActiveCategoryOrder, categoryOrder)) {
        dispatch.restaurant.setActiveCategoryOrder(categoryOrder)
        dispatch.restaurant.updateActiveProducts()
      }
    },
    getActiveCategoryOrder() {
      return getState().restaurant.activeCategoryOrder || []
    },
    updateActiveProducts() {
      const activeProducts = {}
      const activeCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()

      for (const categoryId of activeCategoryOrder) {
        const productOrder = dispatch.restaurant.getProductOrder(categoryId)
        for (const productId of productOrder) {
          // Check if product exists
          const productDetails = dispatch.restaurant.getProductDetails(productId)
          if (!productDetails) {
            continue
          }
          // Check if product is active
          if (!dispatch.restaurant.getIsProductActive(productId)) {
            continue
          }
          const { orderType = 'all' } = productDetails
          if (
            !(
              orderType === 'all' ||
              (orderType === 'dine-in' && dispatch.restaurant.getIsDineIn()) ||
              (orderType === 'dine-out' && !dispatch.restaurant.getIsDineIn())
            )
          ) {
            continue
          }

          // Add product options
          if (productDetails.options && productDetails.options.length > 0) {
            for (const productOptionId of productDetails.options) {
              const productOptionDetails = dispatch.restaurant.getProductDetails(productId)
              if (productOptionDetails) {
                activeProducts[productOptionId] = productOptionDetails
              }
            }
          }
          activeProducts[productId] = productDetails
        }
      }
      dispatch.restaurant.setActiveProducts(activeProducts)
    },
    getActiveProducts() {
      return getState().restaurant.activeProducts
    },
    getProductDetails(productId) {
      return getState().restaurant.Products[productId]
    },
    getModifierDetails(modifierId) {
      return getState().restaurant.Modifiers[modifierId]
    },
    getProductOptions(productId) {
      if (!productId) return []
      return get(dispatch.restaurant.getProductDetails(productId), 'options', [])
    },
    getProductOptionsDetails(productId) {
      // get array of optionIds
      const options = dispatch.restaurant.getProductOptions(productId)
      if (options) {
        return options.map(optionId => dispatch.restaurant.getProductDetails(optionId))
      }
      return null
    },
    getProductModifiers(productId) {
      if (!productId) return []
      return get(dispatch.restaurant.getProductDetails(productId), 'modifiers', [])
    },
    getProducts() {
      return getState().restaurant.Products
    },
    getCategories() {
      return getState().restaurant.Categories
    },
    getModifiers() {
      return getState().restaurant.Modifiers
    },
    getTables() {
      return getState().restaurant.Tables
    },
    getTable(tableId) {
      return getState().restaurant.Tables[tableId]
    },
    getTableNumber() {
      const tableId = dispatch.restaurant.getTableId()
      if (tableId) {
        return getState().restaurant.Tables[tableId].tableNumber
      } else {
        return ''
      }
    },
    getCategory(categoryId) {
      return dispatch.restaurant.getCategories()[categoryId]
    },
    getCategoryImageUrl(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'imageUrl')
    },
    getProductOrder(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'productOrder', [])
    },
    getProductsInOrder(categoryId, admin = false) {
      const products = dispatch.restaurant.getProducts()
      let productOrder = dispatch.restaurant.getProductOrder(categoryId)
      if (categoryId === 'recent') {
        productOrder = dispatch.user.getRecentOrdersSorted()
      }
      return productOrder.reduce((prev, productId) => {
        const product = products[productId]
        if (product) {
          const { orderType = 'all' } = product
          if (
            admin ||
            orderType === 'all' ||
            (orderType === 'dine-in' && dispatch.restaurant.getIsDineIn()) ||
            (orderType === 'dine-out' && !dispatch.restaurant.getIsDineIn())
          ) {
            prev.push(product)
          }
        }
        return prev
      }, [])
    },
    getOptions(productId) {
      return dispatch.restaurant.getProductDetails(productId).options || []
    },
    getLocations() {
      return getState().restaurant.Locations
    },
    getHasSingleLocation() {
      return Object.keys(dispatch.restaurant.getLocations()).length === 1
    },
    getHasMultipleLocations() {
      return Object.keys(dispatch.restaurant.getLocations()).length > 1
    },
    getLoading() {
      return getState().restaurant.loading || getState().restaurant.restaurantLocationLoading
    },
    getLoadingLocations() {
      return isEmpty(getState().restaurant.Locations)
    },
    getIsProductActive(productId) {
      const productDetails = dispatch.restaurant.getProductDetails(productId)
      return utils.getIsProductActive(productDetails)
    },
    getIsChoiceActive(modifierId, choiceIndex) {
      const modifierDetails = dispatch.restaurant.getModifierDetails(modifierId)
      const choiceDetails = modifierDetails.choices[choiceIndex]
      return utils.getIsProductActive(choiceDetails)
    },
    getIsMenuProductActive(productId) {
      return Object.keys(dispatch.restaurant.getActiveProducts()).includes(productId)
    },
    getRestaurantDoc() {
      return db.collection('Restaurants').doc(dispatch.restaurant.getRestaurantId())
    },
    getSelectedLocationDoc() {
      return dispatch.restaurant.getLocationsDoc().doc(dispatch.restaurant.getSelectedLocationId())
    },
    getCategoriesDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Categories')
    },
    getProductsDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Products')
    },
    getModifiersDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Modifiers')
    },
    getTablesDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Tables')
    },
    getLocationsDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Locations')
    },
    getOrdersDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Orders')
    },
    subscribeRestaurant() {
      const collectionLoadedStatus = {
        Restaurant: false,
        Locations: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = collectionName => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.setLoading(false)
          }
        }
      }
      const unsubsRestaurant = dispatch.restaurant.getRestaurantDoc().onSnapshot(
        snapshot => {
          const restaurantData = snapshot.data()
          setCollectionToLoaded('Restaurant')
          dispatch.restaurant.setRestaurant(restaurantData)
        },
        () => setCollectionToLoaded('Restaurant')
      )
      const unsubsLocations = dispatch.restaurant.getLocationsDoc().onSnapshot(
        snapshot => {
          const locations = {}
          snapshot.forEach(doc => {
            locations[doc.id] = doc.data()
            locations[doc.id].id = doc.id
          })

          // Set locationId if there is only one location
          const locationIds = Object.keys(locations)
          if (locationIds.length === 1) {
            dispatch.restaurant.setLocationId(locationIds[0])
          }

          dispatch.restaurant.setLocations(locations)
          setCollectionToLoaded('Locations')
        },
        () => setCollectionToLoaded('Locations')
      )

      const unsubscribeAndResetRestaurant = () => {
        unsubsRestaurant()
        unsubsLocations()
        dispatch.restaurant.resetRestaurant()
      }
      return unsubscribeAndResetRestaurant
    },
    subscribeRestaurantLocation() {
      dispatch.restaurant.setRestaurantLocationLoading(true)
      const collectionLoadedStatus = {
        Categories: false,
        Products: false,
        Modifiers: false,
        RestaurantLocation: false,
        Tables: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = collectionName => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.updateActiveCategoryOrder()
            dispatch.restaurant.updateActiveProducts()
            dispatch.restaurant.setRestaurantLocationLoading(false)
          }
        }
      }
      const unsubsRestaurantLocation = dispatch.restaurant.getSelectedLocationDoc().onSnapshot(
        snapshot => {
          const selectedLocationData = snapshot.data()
          setCollectionToLoaded('RestaurantLocation')
          dispatch.restaurant.setRestaurant(selectedLocationData)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
        },
        () => setCollectionToLoaded('RestaurantLocation')
      )
      const unsubsCategories = dispatch.restaurant.getCategoriesDoc().onSnapshot(
        snapshot => {
          const categories = {}
          snapshot.forEach(doc => {
            categories[doc.id] = doc.data()
            categories[doc.id].id = doc.id
          })
          dispatch.restaurant.setCategories(categories)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Categories')
        },
        () => setCollectionToLoaded('Categories')
      )
      const unsubsProducts = dispatch.restaurant.getProductsDoc().onSnapshot(
        snapshot => {
          const products = {}
          snapshot.forEach(doc => {
            products[doc.id] = doc.data()
            products[doc.id].id = doc.id
          })
          dispatch.restaurant.setProducts(products)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Products')
        },
        () => setCollectionToLoaded('Products')
      )
      const unsubsModifiers = dispatch.restaurant.getModifiersDoc().onSnapshot(
        snapshot => {
          const modifiers = {}
          snapshot.forEach(doc => {
            modifiers[doc.id] = doc.data()
            modifiers[doc.id].id = doc.id
          })
          dispatch.restaurant.setModifiers(modifiers)
          setCollectionToLoaded('Modifiers')
        },
        () => setCollectionToLoaded('Modifiers')
      )
      const unsubsTables = dispatch.restaurant.getTablesDoc().onSnapshot(
        snapshot => {
          const tables = {}
          snapshot.forEach(doc => {
            tables[doc.id] = doc.data()
            tables[doc.id].id = doc.id
          })
          dispatch.restaurant.setTables(tables)
          setCollectionToLoaded('Tables')
        },
        () => setCollectionToLoaded('Tables')
      )
      const unsubscribeRestaurantLocation = () => {
        unsubsCategories()
        unsubsProducts()
        unsubsModifiers()
        unsubsTables()
        unsubsRestaurantLocation()
      }
      return unsubscribeRestaurantLocation
    },
    subscribeRequest(requestId) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const unsubscribeRequest = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Requests')
        .doc(requestId)
        .onSnapshot(snapshot => {
          if (snapshot.data) {
            const request = snapshot.data()
            if (request.confirmed) {
              dispatch.notification.setMessage({
                message: 'Your request has been confirmed!',
                level: 'success',
              })
              localStorage.setItem('lastConfirmedRequestId', requestId)
              dispatch.restaurant.setConfirmingRequest(false)
              unsubscribeRequest()
            }
          }
        })
    },
  }),
})
